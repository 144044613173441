.container {
  @apply shadow-soft rounded-2xl;
}

.content_list {
  @apply min-h-[calc(100vh-275px)];
}

.content_footer {
  @apply flex justify-end p-4 mb-[-16px];
} 

