.mosleme-table .ant-spin-nested-loading>div>.ant-spin {
    @apply !top-[55px] !max-h-none;
    height: calc(100% - 55px);
}

.mosleme-table .ant-spin-container.ant-spin-blur {
    @apply opacity-100;
}

.mosleme-table .ant-spin-container.ant-spin-blur::after {
    @apply opacity-0;
}

.mosleme-table .ant-spin-container.ant-spin-blur .ant-table-tbody {
    @apply opacity-40;
}

.mosleme-table .ant-checkbox .ant-checkbox-input {
    z-index: 0 !important;
}

.mosleme-table .ant-table-body::-webkit-scrollbar:horizontal {
    display: none;
  }
  /* Scrollbar */
  .mosleme-table .ant-table-body::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Scrollbar Track */
  .mosleme-table .ant-table-body::-webkit-scrollbar-track {
    width: 4px;
    border-right: 10px solid #fff;
    border-left: 10px solid #fff;
  }
  
  /* Scrollbar Thumb */
  .mosleme-table .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    width: 4px;
    border-radius: 16px;
  }
  
  /* Scrollbar Track Pieces */
  .mosleme-table .ant-table-body::-webkit-scrollbar-track-piece:start {
    background: #fff;
  }
  
  .mosleme-table .ant-table-body::-webkit-scrollbar-track-piece:end {
    background: #fff;
  }
  
.mosleme-table .ant-table-tbody {
  @apply relative;
}

.mosleme-table .ant-spin-nested-loading {
  @apply relative;
}

.mosleme-table .ant-spin-container {
  @apply relative;
}

.mosleme-table .ant-spin-blur {
  @apply opacity-0;
}

.mosleme-table .ant-table-loading {
  @apply !bg-white;
}

.mosleme-table .ant-table-loading .ant-table-body {
  @apply !bg-white;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
  
  